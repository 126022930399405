import axios from 'axios';
import serverInfo from '../util/globals';

export async function getprezzofinale(idListino, validoDa, email, idpalestra, token, palcliente) {
    const formData = new FormData();
    formData.append('idListino', idListino);
    formData.append('validoDa', validoDa);
    formData.append('website', serverInfo.domain);
    formData.append('email',email);
    formData.append('lic','000');
    formData.append('palestra',idpalestra);
    formData.append('palcliente',palcliente?palcliente:"");
    const response = await axios.post(
    serverInfo.namesec+'/abb/getPrezzoFinaleWp',formData,
    {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+token
        },
        });
        return response.data;  
}

export async function getPalClienteFromAbb(idabbonamento, email, token) {
    const formData = new FormData();
    formData.append('idabbonamento', idabbonamento);
    formData.append('website', serverInfo.domain);
    formData.append('email',email);
    const response = await axios.post(
    serverInfo.namesec+'/abb/getPalestracliente',formData,
    {
        withCredentials: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+token
        },
        });
        return response.data;  
}
