import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";
import { caricasconto } from "../DAO/scontoDAO";
import serverInfo from "../util/globals";
import { getprezzofinale } from "../DAO/abbonamentoDAO";
import { getpalestreassociateadmin } from "../DAO/palestraDAO";

export default function SceltaAbbonamentoUtente () {
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
  const token = useSelector(state => state.gestoreUtente.token);
  
        const [listini, setlistini] = useState();
        
        const [idListino, setidlistino]  = useState();
        const [validoDa,setvalidoda]  = useState();
        const [visible, setvisible]  = useState(false);
        const [pagamentoVisible, setpagamentovisibile]  = useState(false);
        const [messaggio, setmessaggio] = useState();
        const [prezzofinale, setprezzofinale] = useState(null);
        const [abbonamento, setabbonamento] =useState();
        const [messaggioConfermaAbbonamento, setmessaggioconferma] = useState();
        const [codicesconto, setcodicesconto] = useState()
        const [sconto, setsconto] = useState()
        const [palestreassociate, setpalestreassociate] = useState([])
        const [palcliente, setpalcliente] = useState('')

        function applicasconto(){
            async function getsconto(){
                const result=await caricasconto(anagrafica.email, palestra.id,codicesconto, anagrafica.id)
                
                    setsconto(result)
                
                if (!result){
                    alert("Codice sconto "+codicesconto+" non valido")
                }
            }
            getsconto();
        }

    function caricapalassociate(){
        async function getpalass(){
            const result=await getpalestreassociateadmin(email, token);
            setpalestreassociate(result)
        }
        getpalass()
    }

    useEffect(() => {
        //carica la lista dei listini della palestra
        const formData = new FormData();
        formData.append('website', serverInfo.domain);
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
        axios.post(serverInfo.namesec+"/arch/listiniUtenteRest", formData,
        {
          withCredentials: true,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+token
          },
        })
		.then(response=>{
            console.log(response)
            setlistini(response.data)
        });
        if (palestra.id===80){
            caricapalassociate()
        }
	},[palestra, email, anagrafica])
  
   
    function pagaOra() {
        localStorage.setItem("dapagare","abb_"+abbonamento.id)
        window.open((prezzofinale.pagaoraurl&&prezzofinale.pagaoraurl!=='undefined'?prezzofinale.pagaoraurl:'https://annotatio.online/pagamento-utente-popup')+'?&datap=abb_'+abbonamento.id,"_blank")
    }
    
    function handleSubmit() {

        async function getprezo(){
            const result=await getprezzofinale(idListino, validoDa, email, palestra.id, token, palcliente);
            console.log("prezzofinale: "+result.totale)
            setprezzofinale(result)
            setvisible(true)
            setpagamentovisibile(false);
        }
        if (!idListino||idListino===''){
            setmessaggio("Listino obbligatorio")
            return
        }
        if (!validoDa||validoDa===''){
            setmessaggio("Data Inizio Abbonamento obbligatoria")
            return
        }
        if (palestra.id===80){
            if (!palcliente||palcliente===0){
                setmessaggio("Selezionare l'attività commerciale da abbonare")
                return
            }
            
        }
        getprezo();
    }

        function confermaNuovoAbbonamento() {
            if (!idListino||idListino===''){
               setmessaggio("Listino obbligatorio")
                return
            }
            if (!validoDa||validoDa===''){
                setmessaggio("Data Inizio Abbonamento obbligatoria")
                return
            }
            if (palestra.id===80){
                if (!palcliente||palcliente===0){
                    setmessaggio("Selezionare l'attività commerciale da abbonare")
                    return
                }
                
            }
            const formData = new FormData();
            formData.append('idListino', idListino);
            formData.append('validoDa', validoDa);
            formData.append('website', serverInfo.domain);
            formData.append('email',email);
            formData.append('lic','000');
            formData.append('palestra',palestra.id);
            formData.append('idAnagrafica',anagrafica.id);
            formData.append('codicesconto',codicesconto);
            formData.append('palcliente',palcliente?palcliente:"");
            axios.post(serverInfo.namesec+'/abb/nuovoAbbonamentoPrePayWp',formData,
            {
                withCredentials: true,
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'multipart/form-data',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Methods': 'POST',
                  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                  'Access-Control-Allow-Credentials': 'true',
                  'Authorization' :'Bearer '+token
                },
              })
            .then((result) => {
                console.log("nuovoAbbonamentoPrePayWp: "+result.data.id)
                setabbonamento(result.data);
                setvisible(true);
                setpagamentovisibile(true);
                setmessaggioconferma("Abbonamento inserito con successo");
            }).catch(err => {
                if (err.response){
            alert(err.response.data.message);
                }
                else{
                    alert(err)
                }
            });
            }

        const titolocentrato = {
            textAlign: 'center'
            };
       
        var style1={
			color:"white"
		}

      return (
              <div>
                    <span>
                        <h3 style={titolocentrato}>Ti stai abbonando a <i>{palestra?palestra.palestra:""}</i></h3>
                        
                        {palestra.id===80&&
                        
                            palestreassociate&&Array.isArray(palestreassociate)&&palestreassociate.length>0&&
                        <label>Seleziona l&apos;attività commerciale da abbonare 
                            <select id="palassselected" name="palassselected" onChange={() => setpalcliente(document.getElementById("palassselected").value)}>
                            <option value="">--seleziona un&apos;attività commerciale--</option>
                            {palestreassociate.map(palass => (
                                <option key={palass.id} value={palass.id}>{palass.palestra}</option>
                            ))}
                            </select></label>
                        
                        }
                    
                    {
                        listini?
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h4>
                                        <label htmlFor="idListino">Scegli il tipo di abbonamento</label>
                                    </h4>
                                </div>
                                <div className="col">
                                <select name="idListino" id="idListino" onChange={(e) =>setidlistino(e.target.value)} value={idListino} required="required">
                                    <option>-- Lista abbonamento --</option>
                                {listini.map(listino =>(
                                        <option key={listino.id} value={listino.id}>{listino.tipoAbbonamento.descrizione +" "+listino.periodo.descrizione} ({listino.prezzo}€)</option>
                                        )
                                )}
                                </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <h4>
                                        <label htmlFor="validoDa">Data inizio abbonamento</label>
                                    </h4>
                                </div>
                                <div className="col">
                                    <input id="validoDa" type="date" name="validoDa" onChange={(e) =>setvalidoda(e.target.value)} required="required" />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <a type="button" className="btn btn-primary" onClick={() => {handleSubmit()}} style={style1}>Calcola il prezzo</a>
                                </div>
                            </div>

                            {
                                prezzofinale
                                &&prezzofinale.totale>0
                                &&visible?
                                <span>
                                    {prezzofinale.warning?
                                    <div className="alert alert-warning" role="alert">
                                    {prezzofinale.warning}
                                    </div>
                                    :null}
                                    

                                    <table className="table" style={{width:'50%'}}>
                                        <tr>
                                            <td>Prezzo Corso</td><td style={{textAlign:'right'}}>{prezzofinale.prezzo}</td>
                                        </tr>
                                        <tr>
                                            <td>- Sconto</td>
                                        <td style={{textAlign:'right'}}>
                                        <input placeholder="codice sconto" type="text" name="codicesconto" value={codicesconto} onChange={(e) => setcodicesconto(e.target.value) } />
                                        <button onClick={applicasconto}>Applica codice</button> &nbsp;&nbsp;&nbsp;
                                            {sconto&&sconto.id?((prezzofinale.totale-prezzofinale.prezzoiscrizione)*sconto.valoreSconto/100):prezzofinale.sconto}
                                            </td>
                                        </tr>
                                        <tr>
                                        <td>+ Iscrizione</td><td style={{textAlign:'right'}}>{prezzofinale.prezzoiscrizione}</td>
                                        </tr>
                                       
                                        <tr>
                                        <td>DA PAGARE</td><td style={{textAlign:'right'}}><b>
                                            {sconto&&sconto.id?(prezzofinale.totale-((prezzofinale.totale-prezzofinale.prezzoiscrizione)*sconto.valoreSconto/100)):prezzofinale.totale}</b>
                                            </td>
                                        </tr>
                                        </table>
                                        {!messaggioConfermaAbbonamento&&<a type="button" className="btn btn-primary" onClick={() => confermaNuovoAbbonamento()} style={style1}>Conferma</a>}
                                        
                                        {/* <form method="POST" action="/auth/nuovoAbbonamentoPrePay">
                                            <input type="hidden" name="idListino" value={.idListino} />
                                            <input type="hidden" name="validoDa" value={.validoDa} />
                                        <button type="submit">Conferma</button>
"AV884cbUbhv3E1-POK-UYZBBYtzBsLcfNbMKB8AjmPgQKWSAM_1MAZhNLvmvF5gCGmZExL93mlqUM4f9"
                                        </form> */}
                                        {messaggioConfermaAbbonamento!==''?
                                            <div>{messaggioConfermaAbbonamento}</div>
                                        :
                                            ""
                                        }
                                        {pagamentoVisible?
                                        prezzofinale.ppid.indexOf("XXX")>=0?
                                                <div className="alert alert-dark" role="alert">È necessario effettuare il pagamento in struttura</div>
                                            :
                                            <div>
                                                <a className="btn btn-primary" role="button" onClick={pagaOra}>Paga ora</a>
                                            </div>
                                        :null}
                                    
                                        
                                        
                                    </span>
                                    
                                    :null
                            }
                            {
                                prezzofinale
                                &&prezzofinale.errori?
                                <span>{prezzofinale.errori}</span>
                                :null
                            }
                            
                            
                        </div>
                        :null
                    }
                    {(!listini||listini.length===0)?<p>Nessun Listino Trovato</p>:null}
                </span>
                {messaggio?
                    <div className="alert alert-secondary" role="alert">
                    {messaggio}
                    </div>
                :null}
              </div>
      );
  }