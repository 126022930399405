/* eslint-disable no-mixed-spaces-and-tabs */
import axios from 'axios';
import serverInfo from '../util/globals';

export async function getAnagrafica(email, lic, palestra, idanag) {
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email', email);
  formData.append('lic', lic);
  formData.append('palestra', palestra);
  formData.append('id', idanag);
  const response = await axios.post(
    serverInfo.name+'/getDettaglioAnagraficaSelezionata',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log(response);;
  return response.data;
}

export async function getAnagraficheCorrelate(email, palestra, token) {
  const formData = new FormData();
  formData.append('website', serverInfo.domain);
  formData.append('email', email);
  formData.append('palestra', palestra?palestra:"");
  const response = await axios.post(serverInfo.namesec+'/user/getAnagrafcheAssociate',
  formData,
	  {
	  headers: {
		Accept: 'application/json',
		'Content-Type': 'multipart/form-data',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'POST',
		'Access-Control-Allow-Headers': 'Content-Type, Authorization',
		'Access-Control-Allow-Credentials': 'true',
		'Authorization' :'Bearer '+token
	  },
	  },
  );

  return response.data;
}

export async function salvaAnagrafica(email, lic, palestra, anagrafica) {
  const formData = new FormData();
  formData.append('website', "annotatio.online");
  formData.append('email', email);
  formData.append('lic', lic);
  formData.append('palestra', palestra);
  formData.append('id', anagrafica.id);
  formData.append('nome', anagrafica.nome);
  formData.append('cognome', anagrafica.cognome);
  formData.append('sesso', anagrafica.sesso);
  formData.append('datanascita', anagrafica.datanascita);
  formData.append('luogonascita', anagrafica.luogonascita);
  formData.append('codiceFiscale', anagrafica.codiceFiscale);
  formData.append('indirizzo', anagrafica.indirizzo);
  formData.append('citta', anagrafica.citta);
  formData.append('cap', anagrafica.cap);
  formData.append('telefono', anagrafica.telefono);
  formData.append('emailanag', anagrafica.email);
  formData.append('cognomeNomeGenitore', anagrafica.cognomeNomeGenitore);
  formData.append('dataNascitaGenitore', anagrafica.dataNascitaGenitore);
  formData.append('codiceFiscaleGenitore', anagrafica.codiceFiscaleGenitore);
  formData.append('indirizzoGenitore', anagrafica.indirizzoGenitore);
  formData.append('cittaGenitore', anagrafica.cittaGenitore);
  formData.append('capGenitore', anagrafica.capGenitore);
  const response = await axios.post(
    serverInfo.namesec+'/user/modAnagraficaSelezionata',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function insAnagrafica(email, palestra, anagrafica) {
  const formData = new FormData();
  formData.append('website', "annotatio.online");
  formData.append('email', email);
  formData.append('lic', '00000000');
  formData.append('palestra', palestra);
  formData.append('id', 0);
  formData.append('nome', anagrafica.nome?anagrafica.nome:'');
  formData.append('cognome', anagrafica.cognome?anagrafica.cognome:'');
  formData.append('sesso', anagrafica.sesso?anagrafica.sesso:'');
  formData.append('datanascita', anagrafica.datanascita?anagrafica.datanascita:'');
  formData.append('luogonascita', anagrafica.luogonascita?anagrafica.luogonascita:'');
  formData.append('codiceFiscale', anagrafica.codiceFiscale?anagrafica.codiceFiscale:'');
  formData.append('indirizzo', anagrafica.indirizzo?anagrafica.indirizzo:'');
  formData.append('citta', anagrafica.citta?anagrafica.citta:'');
  formData.append('cap', anagrafica.cap?anagrafica.cap:'');
  formData.append('telefono', anagrafica.telefono?anagrafica.telefono:'');
  formData.append('emailanag', anagrafica.email);
  formData.append('cognomeNomeGenitore', anagrafica.cognomeNomeGenitore?anagrafica.cognomeNomeGenitore:'');
  formData.append('dataNascitaGenitore', anagrafica.dataNascitaGenitore?anagrafica.dataNascitaGenitore:'');
  formData.append('codiceFiscaleGenitore', anagrafica.codiceFiscaleGenitore?anagrafica.codiceFiscaleGenitore:'');
  formData.append('indirizzoGenitore', anagrafica.indirizzoGenitore?anagrafica.indirizzoGenitore:'');
  formData.append('cittaGenitore', anagrafica.cittaGenitore?anagrafica.cittaGenitore:'');
  formData.append('capGenitore', anagrafica.capGenitore?anagrafica.capGenitore:'');
  const response = await axios.post(
    serverInfo.namesec+'/user/insAnagraficaSelezionata',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function getlogininfo(email, fp) {
  //console.log('anagraficadao - getlogininfo '+email);
  const formData = new FormData();
  formData.append('email', email);
  formData.append('fp',fp);
  const response = await axios.post(serverInfo.name+'/getlogininfo',formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}

export async function getAnagraficaByMailAndPalestra(email, idpalestra, fp, token) {
  console.log('anagraficadao - loginapp '+email+' - ' + idpalestra +' toke: '+token);
  const formData = new FormData();
  formData.append('email', email);
  formData.append('idpalestra', idpalestra);
  formData.append('fp',fp);
  const response = await axios.post(serverInfo.namesec+'/user/getAnagraficaByMailAndPalestra',formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+token
    },
  },
  );
  return response.data;
}

export async function confermacodiceloginapp(email, codice, fp) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('code',  codice);
  formData.append('fp',fp);
  const response = await axios.post(
    serverInfo.name+'/confermaloginapp',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}

export async function registrazioneapp(email, nome, cognome, telefono, datanascita, idpalestra, fp) {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('nome',  nome);
  formData.append('cognome',  cognome);
  formData.append('telefono',  telefono);
  formData.append('idpalestra',  idpalestra);
  formData.append('datanascita',  datanascita);
  formData.append('fp',fp);
  const response = await axios.post(
    serverInfo.name+'/registrazioneapp',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}

export async function cercaAnagrafica(email, palestra, cognome){
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email',email);
  formData.append('lic','00000000');
  formData.append('term',cognome);
  formData.append('palestra',palestra);
  const response = await axios.post(serverInfo.name+'/cercaAnagraficaPerCognomeJSON',
  formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}

export async function cercaAnagrafiche2(email, palestra, nomecognome, nome, cognome, valide, saldati, tipoabbonamento, periodo, dataDa, dataA, effettuatoDa, effettuatoA,mostraAbbonamenti, istesserato){
  const formData = new FormData();
        formData.append('website', 'annotatio.online');
        formData.append('email',email);
        formData.append('lic','00000000');
        formData.append('palestra',palestra);
        formData.append('nomecognome', nomecognome?nomecognome:'');
        formData.append('nome', nome?nome:'');
        formData.append('cognome', cognome?cognome:'');
       
        formData.append('valide', valide?valide:'');
        formData.append('saldati', saldati?saldati:'');
        formData.append('idTipoAbbonamento', tipoabbonamento?tipoabbonamento:'');
        formData.append('idPeriodo', periodo?periodo:'');

        formData.append('dataDa', dataDa?dataDa:'');
        formData.append('dataA', dataA?dataA:'');
        formData.append('effettuatoDa', effettuatoDa?effettuatoDa:'');
        formData.append('effettuatoA', effettuatoA?effettuatoA:'');
        formData.append('mostraAbbonamenti', mostraAbbonamenti?mostraAbbonamenti:'');
        
        formData.append('istesserato', istesserato);
        const response = await axios.post(serverInfo.name+'/cercaAnagrafiche2',
  formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}

export async function eliminaAnagrafica(email, palestra, idanag) {
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
  formData.append('email', email);
  formData.append('lic', '00000000');
  formData.append('palestra', palestra);
  formData.append('id', idanag);
  const response = await axios.post(
    serverInfo.name+'/eliminaAnagSelezionata',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log(response);;
  return response.data;
}

export async function confermaincarico(palestra, idanag){
  const formData = new FormData();
  formData.append('palestra', palestra);
  formData.append('idanag', idanag);
  const response = await axios.post(
    serverInfo.namesec+'/anag/confermaincarico',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response);;
  return response.data;
}

export async function confermainfprivacy(palestra, idanag){
  const formData = new FormData();
  formData.append('palestra', palestra);
  formData.append('idanag', idanag);
  const response = await axios.post(
    serverInfo.namesec+'/anag/confermainfprivacy',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response);;
  return response.data;
}

export async function getutente(email, token){
  console.log(token, "token")
  const formData = new FormData();
  formData.append('email', email);
  const response = await axios.post(
    serverInfo.namesec+'/wp/checkUser',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+token
      },
    },
  );
  //console.log(response);;
  return response.data;
}