import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import MessaggiClienti from './messaggi/MessaggiCliente';
import InvioMessaggioCliente from './messaggi/InvioMessaggioCliente';
import ListaCalendariCliente from './calendario/ListaCalendariCliente';
import PrenotazioniCliente from './prenotazioni/PrenotazioniCliente';
import ListaSchedeCliente from './schedasalaattrezzi/ListaSchedeCliente';
import SceltaAbbonamentoUtente from './abbonamenti/SceltaAbbonamentoUtente';
import Qrcode from './anagrafica/Qrcode';
import AnagraficheAssociate from './anagrafica/AnagraficheAssociate';
import Ordini from './ordini/Ordini';
import ListaSchedeCompCorp from './schedasalaattrezzi/composizionecorporea/ListaSchedeCompCorp';
import NuovaAnagrafica from './anagrafica/NuovaAnagrafica';
import PagamentoUtentePopup from './abbonamenti/PagamentoUtentePopup';
import QualeTipologia from './calendario/QualeTipologia';
import Prodotti from './prodotti/Prodotti';
import AbbonamentiCliente from './riquadriUtente/AbbonamentiCliente';

import AnagraficaCliente from './anagrafica/AnagraficaCliente';
import { Provider, useDispatch } from 'react-redux';
import store from './STORE/store'
import AppRoute from './AppRoute';
import LoadMainThings from './util/LoadMainThings';
import AssociaPalestraAdUtente from './anagrafica/AssociaPalestra';

var appannotatio=document.getElementById("appannotatio");


var first_name=''
var last_name=''

 // const first_name=appannotatio.getAttribute("first_name");
 // const last_name=appannotatio.getAttribute("last_name");
 // const telefono=appannotatio.getAttribute("telefono");

if (appannotatio){
  var url_string = window.location.href;
  var url = new URL(url_string);
  var emailValue = url.searchParams.get("email");
  if (emailValue&&emailValue!==''){
    localStorage.setItem('email',emailValue);
  }

  
  var other = url.searchParams.get("other");
  if (other){
    first_name=other.split("___")[0]
    last_name=other.split("___")[1]
    localStorage.setItem('other',other);
  }

  var idpalestra= url.searchParams.get("idpalestra");
  if (idpalestra){
    localStorage.setItem('palassociata',idpalestra);
    localStorage.setItem('palselezionata',idpalestra);
    localStorage.setItem('palestracalendario',idpalestra);
  }

  var urltoredirect = url.searchParams.get("url");

  var jsonposts= url.searchParams.get("jsonposts");
if (first_name){
  localStorage.setItem('first_name',first_name);
}
if (last_name){ 
  localStorage.setItem('last_name',last_name);
}

var idtipo = url.searchParams.get("idtipo");
if (idtipo){
  localStorage.setItem('tipocorso',idtipo);
}

  const nome=appannotatio.getAttribute("first_name");
  const cognome=appannotatio.getAttribute("last_name");
  const telefono=appannotatio.getAttribute("telefono");

var linkannotatio = url.searchParams.get("linkannotatio");
const tkn=appannotatio.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
  <AppRoute email={emailValue} nome={nome} cognome={cognome} telefono={telefono} showheader={true} showtoolbar={true} />
  </Provider>,
  appannotatio
  )
}

var messaggicliente=document.getElementById("messaggicliente");
if (messaggicliente){
  const email=messaggicliente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=messaggicliente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=messaggicliente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=messaggicliente.getAttribute("t");
  window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <MessaggiClienti email={email} link={linkannotatio}/>
        </Provider>
      ,
      messaggicliente
    );
}

var inviamessaggiocliente=document.getElementById("inviamessaggiocliente");
if (inviamessaggiocliente){
  const email=inviamessaggiocliente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=inviamessaggiocliente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=inviamessaggiocliente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=inviamessaggiocliente.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <InvioMessaggioCliente email={email} link={linkannotatio}/>
        </Provider>
      ,
      inviamessaggiocliente
    );
}

var dettanagcliente=document.getElementById("dettanagcliente");
if (dettanagcliente){
  const email=dettanagcliente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=dettanagcliente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=dettanagcliente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const linkannotatio2=dettanagcliente.getAttribute("linkannotatio2");
  console.log("linkannotatio2 "+linkannotatio2)
  
  const tkn=dettanagcliente.getAttribute("t");
  window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <AnagraficaCliente  email={email} link={linkannotatio} link2={linkannotatio2}/>
        </Provider>
      ,
      dettanagcliente
    );
}

var nuovaanagcliente=document.getElementById("nuovaanagcliente");
if (nuovaanagcliente){
  const email=nuovaanagcliente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }

  const key=nuovaanagcliente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=nuovaanagcliente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=nuovaanagcliente.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <NuovaAnagrafica email={email} link={linkannotatio}/>
        </Provider>
      ,
      nuovaanagcliente
    );
}

var calendarioCliente=document.getElementById("calendarioCliente");
 if (calendarioCliente){
   const email=calendarioCliente.getAttribute("email");
   if (email&&email!==''){
    localStorage.setItem('email',email);
  }
   const key=calendarioCliente.getAttribute("key");
   localStorage.setItem('key',key);

   const linkannotatio=calendarioCliente.getAttribute("linkannotatio");
   console.log("linkannotatio "+linkannotatio)

   const calendario=calendarioCliente.getAttribute("calendario");
   console.log("calendario "+calendario)

   const nome=calendarioCliente.getAttribute("first_name");
  const cognome=calendarioCliente.getAttribute("last_name");
  const telefono=calendarioCliente.getAttribute("telefono");

  const tkn=calendarioCliente.getAttribute("t");
    window.$t=tkn;

   ReactDOM.render(
    <Provider store={store}>
         <AppRoute email={email} nome={nome} cognome={cognome} telefono={telefono} />
         </Provider>
       ,
       calendarioCliente
     );
 }

 var dashboardcliente=document.getElementById("dashboardcliente");
 if (dashboardcliente){
   const email=dashboardcliente.getAttribute("email");
   if (email&&email!==''){
    localStorage.setItem('email',email);
  }
   const key=dashboardcliente.getAttribute("key");
   localStorage.setItem('key',key);

   const linkannotatio=dashboardcliente.getAttribute("linkannotatio");
   console.log("linkannotatio "+linkannotatio)

   const calendario=dashboardcliente.getAttribute("calendario");
   console.log("calendario "+calendario)

   const nome=dashboardcliente.getAttribute("first_name");
  const cognome=dashboardcliente.getAttribute("last_name");
  const telefono=dashboardcliente.getAttribute("telefono");

  const tkn=dashboardcliente.getAttribute("t");
    window.$t=tkn;

   ReactDOM.render(
    <Provider store={store}>
         <AppRoute showtoolbar={true} showheader={true} email={email} nome={nome} cognome={cognome} telefono={telefono} />
         </Provider>
       ,
       dashboardcliente
     );
 }

 var calendarioClienteTipologia=document.getElementById("calendarioClienteTipologia");
 if (calendarioClienteTipologia){
   const email=calendarioClienteTipologia.getAttribute("email");
   if (email&&email!==''){
    localStorage.setItem('email',email);
  }
   const key=calendarioClienteTipologia.getAttribute("key");
   localStorage.setItem('key',key);

   const linkannotatio=calendarioClienteTipologia.getAttribute("linkannotatio");
   console.log("linkannotatio "+linkannotatio)

   const calendario=calendarioClienteTipologia.getAttribute("calendario");
   console.log("calendario "+calendario)

   const tkn=calendarioClienteTipologia.getAttribute("t");
    window.$t=tkn;

   ReactDOM.render(
         <QualeTipologia email={email} link={linkannotatio} calendario={calendario} />
       ,
       calendarioClienteTipologia
     );
 }

 var listaCalendariCliente=document.getElementById("listaCalendariCliente");

if (listaCalendariCliente){
  const email=listaCalendariCliente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }

  const key=listaCalendariCliente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=listaCalendariCliente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=listaCalendariCliente.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
        <ListaCalendariCliente email={email} link={linkannotatio}/>
      ,
      listaCalendariCliente
    );
}

var prenotazioniCliente=document.getElementById("prenotazioniCliente");

if (prenotazioniCliente){
  const email=prenotazioniCliente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }

  const key=prenotazioniCliente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=prenotazioniCliente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=prenotazioniCliente.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <PrenotazioniCliente email={email} link={linkannotatio}/>
        </Provider>
      ,
      prenotazioniCliente
    );
}

var schedeCliente=document.getElementById("schedeCliente");
if (schedeCliente){
  const email=schedeCliente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=schedeCliente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=schedeCliente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=schedeCliente.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} />
        <ListaSchedeCliente email={email} link={linkannotatio} />
        </Provider>
      ,
      schedeCliente
    );
}

var schedeCCCliente=document.getElementById("schedeCCCliente");
if (schedeCCCliente){
  const email=schedeCCCliente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=schedeCCCliente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=schedeCCCliente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=schedeCCCliente.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} showDebugInfo={true} />
        <ListaSchedeCompCorp email={email} link={linkannotatio} />
        </Provider>
      ,
      schedeCCCliente
    );
}

var sceltaAbbUtente=document.getElementById("sceltaAbbUtente");
if (sceltaAbbUtente){
  
  const email=sceltaAbbUtente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=sceltaAbbUtente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=sceltaAbbUtente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=sceltaAbbUtente.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
        <SceltaAbbonamentoUtente email={email} link={linkannotatio}/>
      ,
      sceltaAbbUtente
    );
}

var pagamentoUtentePopup=document.getElementById("pagamentoUtentePopup");
if (pagamentoUtentePopup){
  const email=pagamentoUtentePopup.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=pagamentoUtentePopup.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=pagamentoUtentePopup.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=pagamentoUtentePopup.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings email={email} showDebugInfo={true} />
        <PagamentoUtentePopup email={email} link={linkannotatio}/>
        </Provider>
      ,
      pagamentoUtentePopup
    );
}

var qrcode=document.getElementById("qrcode");
if (qrcode){

  ReactDOM.render(
        <Qrcode />
      ,
      qrcode
    );
}

var anagraficheassociate=document.getElementById("anagraficheassociate");
if (anagraficheassociate){
  const email=anagraficheassociate.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const nome=anagraficheassociate.getAttribute("first_name");
  const cognome=anagraficheassociate.getAttribute("last_name");
  const telefono=anagraficheassociate.getAttribute("telefono");
  const showlistanag=anagraficheassociate.getAttribute("showlistanag");

  const linkannotatio=anagraficheassociate.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)
  // if (telefono){
  //   localStorage.setItem('telefono',telefono);
  // }
  const tkn=anagraficheassociate.getAttribute("t");
  window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
         <LoadMainThings showDebugInfo={false} email={email} nome={nome}  t={tkn} cognome={cognome} telefono={telefono} />
        <AnagraficheAssociate email={email} link={linkannotatio} 
        showlistanag={showlistanag} />
        </Provider>
      ,
      anagraficheassociate
    );
}

var ordiniClient=document.getElementById("ordiniClient");
if (ordiniClient){
  const email=ordiniClient.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=ordiniClient.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=ordiniClient.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=ordiniClient.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
      <Provider store={store}>
        <Ordini email={email} link={linkannotatio}/>
        </Provider>
      ,
      ordiniClient
    );
}

var tipiCorsoCard=document.getElementById("tipiCorsoCard");
if (tipiCorsoCard){
  const email=tipiCorsoCard.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const nome=tipiCorsoCard.getAttribute("first_name");
  const cognome=tipiCorsoCard.getAttribute("last_name");
  const telefono=tipiCorsoCard.getAttribute("telefono");
  
  const key=tipiCorsoCard.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=tipiCorsoCard.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=tipiCorsoCard.getAttribute("t");
    window.$t=tkn;

  ReactDOM.render(
    <Provider store={store}>
  <AppRoute email={email} nome={nome} cognome={cognome} telefono={telefono} showheader={false} showtoolbar={false} />
  </Provider>,
      tipiCorsoCard
    );
}

var prodotti=document.getElementById("prodotti");
if (prodotti){
  const email=prodotti.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
    const key=prodotti.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=prodotti.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const calendario=prodotti.getAttribute("calendario");
  console.log("calendario "+calendario)
  const tkn=prodotti.getAttribute("t");
  window.$t=tkn;
  ReactDOM.render(
        <Prodotti email={email} link={linkannotatio} calendario={calendario} />
      ,
      prodotti
    );
}

var abbonamentiCliente=document.getElementById("abbonamentiCliente");
if (abbonamentiCliente){
  const email=abbonamentiCliente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=abbonamentiCliente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=abbonamentiCliente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const linkannotatio2=abbonamentiCliente.getAttribute("linkannotatio2");

  const calendario=abbonamentiCliente.getAttribute("calendario");
  console.log("calendario "+calendario)

  const tkn=abbonamentiCliente.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings showDebugInfo={true} email={email} />
        <AbbonamentiCliente email={email} link={linkannotatio} link2={linkannotatio2} calendario={calendario} />
        </Provider>
      ,
      abbonamentiCliente
    );
}

var associapalestrautente=document.getElementById("associapalestrautente");
if (associapalestrautente){
  const email=associapalestrautente.getAttribute("email");
  if (email&&email!==''){
    localStorage.setItem('email',email);
  }
  const key=associapalestrautente.getAttribute("key");
  localStorage.setItem('key',key);

  const linkannotatio=associapalestrautente.getAttribute("linkannotatio");
  console.log("linkannotatio "+linkannotatio)

  const tkn=associapalestrautente.getAttribute("t");
    window.$t=tkn;
  ReactDOM.render(
    <Provider store={store}>
      <LoadMainThings showDebugInfo={true} email={email} />
        <AssociaPalestraAdUtente email={email}
         link={linkannotatio} />
        </Provider>
      ,
      associapalestrautente
    );
}

var logoutButtons=document.querySelectorAll('.logoutlink')
    if (logoutButtons&&logoutButtons.length>0){
      for (var i=0; i<logoutButtons.length; i++){
        logoutButtons[i].addEventListener('click', () => {
          localStorage.clear()
          if (window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage('clean');
           }
        })
      }
    }
    else{
      logoutButtons=document.querySelectorAll('.login_logout_class')
      if (logoutButtons&&logoutButtons.length>0){
        for (var x=0; x<logoutButtons.length; x++){
          logoutButtons[x].addEventListener('click', () => {
            localStorage.clear()
            if (window.ReactNativeWebView){
              window.ReactNativeWebView.postMessage('clean');
             }
          })
        }
      }
      else{
          logoutButtons=document.querySelectorAll('a[href*="logout"]')
          if (logoutButtons&&logoutButtons.length>0){
            for (var c=0; c<logoutButtons.length; c++){
              logoutButtons[c].addEventListener('click', () => {
                localStorage.clear()
                if (window.ReactNativeWebView){
                  window.ReactNativeWebView.postMessage('clean');
                 }
              })
            }
          }
        }
    }

    /*********************
     * !!! Integrazione tra webview e wordpress !!!
     ********************/

    //  var dataToPass=''
    //  if (localStorage.getItem('email')){
    //   dataToPass=localStorage.getItem('email')
    //  }
    //  dataToPass+="___"
    //   if (localStorage.getItem('code')||localStorage.getItem('utente')){
    //       dataToPass+=localStorage.getItem('code')?localStorage.getItem('code'):'SOCIO-'+localStorage.getItem('utente')
    //   }

    //  if (window.ReactNativeWebView){
    //   window.ReactNativeWebView.postMessage(dataToPass);
    //  }
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
