import React from "react";
import { useSelector } from "react-redux";
import {navigate} from 'hookrouter'

export default function Intestazione(){ 
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

    return (
        <span>
            <a onClick={()=> navigate('/annotatioc/')}>
        <img src={palestra&&palestra.id?palestra.logo:"https://annotatio.online/wp-content/uploads/2022/02/logoannotatio.png"} 
        title={palestra.palestra} alt={palestra.palestra} style={{height:'4em', float:'left', marginRight:'15px'}}
         />
         </a>
              {anagrafica&&anagrafica.id?
              <span>
              <h3>{anagrafica.nome + ' '+anagrafica.cognome}</h3>
              <h4>{palestra.palestra}</h4>
              </span>
              :
              <span>
                <h3>{palestra.palestra}</h3>
                <h4>{palestra.descrizioneattivita}</h4>
              </span>}
            
            
        </span>
    );
}