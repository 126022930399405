import axios from 'axios';
import serverInfo from '../util/globals';

export async function getCalendario(email, palestra, idtipo, idanag, token) {
  const formData1 = new FormData();
  formData1.append('website', 'annotatio.online');
  formData1.append('email', email);
  formData1.append('lic', '00000000');
  formData1.append('palestra', palestra);
  formData1.append('calendario', '');
  formData1.append('tipocorso', idtipo);
  formData1.append('idAnagrafica', idanag);

  const response = await axios.post(
    serverInfo.namesec+'/cal/calendarioCliente',
    formData1,
    {
      headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+token
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function gettipicorsobynomecalendario(nomecalendario, palestra,token, visibili) {
  const formData1 = new FormData();
  formData1.append('website', 'annotatio.online');
  formData1.append('palestra', palestra);
  formData1.append('calendario', nomecalendario);
  formData1.append('visibili', visibili);

  const response = await axios.post(
    serverInfo.namesec+'/wp/getTipologieCorsoCalendarioRest',
    formData1,
    {
      headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+token
      },
    },
  );
  //console.log(response);
  return response.data;
}


export async function prenota(email, palestra, idschedulazione, idanag, idcorso) {
  const formData1 = new FormData();
  formData1.append('website', 'annotatio.online');
  formData1.append('email', email);
  formData1.append('lic', '00000000');
  formData1.append('palestra', palestra);
  formData1.append('idSchedulazione', idschedulazione);
  formData1.append('idAnagrafica', idanag);
  formData1.append('idcorso', idcorso);
  const response = await axios.post(
    serverInfo.namesec+'/cal/inserisciPrenotazioneCliente',
    formData1,
    {
      headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response.data);
  return response.data;
}

export async function disdici(email, palestra, idschedulazione, idprenotazione, idanag) {
  const formData1 = new FormData();
  formData1.append('website', 'annotatio.online');
  formData1.append('email', email);
  formData1.append('lic', '00000000');
  formData1.append('palestra', palestra);
  formData1.append('idSchedulazione', idschedulazione);
  formData1.append('idAnagrafica', idanag);
  formData1.append('idPrenotazione', idprenotazione);

  const response = await axios.post(
    serverInfo.namesec+'/abb/annullaPrenotazione',
    formData1,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response.data);
  return response.data;
}

export async function getCalendarioAdmin(email, palestra, idtipo) {
  const formData1 = new FormData();
  formData1.append('website', 'annotatio.online');
  formData1.append('email', email);
  formData1.append('lic', '00000000');
  formData1.append('palestra', palestra);
  formData1.append('calendario', '');
  formData1.append('tipocorso', idtipo);

  const response = await axios.post(
    serverInfo.namesec+'/cal/calendario',
    formData1,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function salvaschedulazione(durata, nummaxprenotazioni, corso, data, ora, id, tipoPrenotazione, email, palestra){
  const formData = new FormData();
	formData.append('duratagiornaliera', 0);
	formData.append('durata', durata);
	formData.append('numMaxPrenotazioni', nummaxprenotazioni);
	formData.append('corso', corso);
	formData.append('data', data);
	formData.append('ora', ora);
	formData.append('id', id);
	formData.append('tipoPrenotazione', tipoPrenotazione);
	formData.append('website', 'annotatio.online');
	formData.append('email',email);
	formData.append('lic','00000000');
	formData.append('palestra',palestra);
	const response = await axios.post(serverInfo.namesec+'/cal/aggiornaSchedulazione',
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
    },
  },
  );
  return response.data;
}

export async function cancellaSchedulazione(email, palestra, idschedulazione){
  //console.log('cancellaSchedulazione ',email,palestra,idschedulazione)
  alert(3);
  const formData = new FormData();
  formData.append('website', 'annotatio.online');
	formData.append('palestra',palestra);
  formData.append('email',email);
	formData.append('lic','00000000');
  formData.append('idSchedulazione',idschedulazione);
  const response = await axios.post(serverInfo.namesec+'/cal/eliminaSchedulazione',
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
    },
  },
);
//console.log(response);
return response.data;
}