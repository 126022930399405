import React, { useEffect } from "react";
import {navigate} from 'hookrouter'
import { useSelector } from "react-redux";

export default function Toolbar(props){
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

    return(
        <span>
            {email&&anagrafica&&anagrafica.id>0&&<nav className="navbar sticky-top navbar-expand-lg navbar-light bg-warning">
                <a className="navbar-brand" href="/annotatio-home">
                    <img src="https://annotatio.online/wp-content/uploads/2022/09/annotatioA512__1_-removebg-preview-1.png" width="30" height="30" alt="Annotatio" />nnotatio
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                    
                    {palestra&&palestra.moduli&&palestra.moduli.includes('CALENDARI')&&<li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='calendari'?"active":"")} onClick={()=> navigate('/annotatioc-prods/')}>Calendari</a>
                    </li>}
                    
                    {palestra&&palestra.moduli&&palestra.moduli.includes('PRENOTAZIONI')&&<li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='cassa'?"active":"")} onClick={()=> navigate('/annotatioc-preno/')}>Prenotazioni</a>
                    </li>}
                    {palestra&&palestra.moduli&&palestra.moduli.includes('ANAGRAFICA')&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='ricanag'?"active":"")} onClick={()=> navigate('/annotatioc-anag/')}>Anagrafica</a>
                    </li>}
                    
                    {palestra&&palestra.moduli&&palestra.moduli.includes('ABBONAMENTI')&&<li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='report'?"active":"")} onClick={()=> navigate('/annotatioc-abbs/')}>Abbonamenti</a>
                    </li>}
                    {palestra&&palestra.moduli&&palestra.moduli.includes('DOCUMENTI')&&<li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='calendari'?"active":"")} onClick={()=> navigate('/annotatioc-docs/')}>Documenti</a>
                    </li>}
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Schede
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        {palestra&&palestra.moduli&&palestra.moduli.includes('PIANOALIMENTARE')&&<a className={"nav-link "+(props.viewselected==='calendari'?"active":"")} onClick={()=> navigate('/annotatioc-dieta/')}>Piano Alimentare</a>}
                        {palestra&&palestra.moduli&&palestra.moduli.includes('SCHEDESALAATTREZZI')&&<a className={"nav-link "+(props.viewselected==='schedeSA'?"active":"")} onClick={()=> navigate('/annotatioc-scheda/')}>Sala Attrezzi</a>}
                        {palestra&&palestra.moduli&&palestra.moduli.includes('SCHEDESALAATTREZZI')&&<a className={"nav-link "+(props.viewselected==='schedeCC'?"active":"")} onClick={()=> navigate('/annotatioc-compcorp/')}>Composizione Corporea e Diario Alimentare</a>}
                        </div>
                    </li>
                    {palestra&&palestra.moduli&&palestra.moduli.includes('ACCESSI')&&<li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='accessi'?"active":"")} onClick={()=> navigate('/annotatioc-accessi/')}>Accessi</a>
                    </li>}
                    {palestra&&palestra.moduli&&palestra.moduli.includes('ORDINI')&&<li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatioc-ordini/')}>Ordini</a>
                    </li>}
                    {palestra&&palestra.moduli&&palestra.moduli.includes('FATTURE')&&<li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatioc-fatture/')}>Fatture</a>
                    </li>}
                    </ul>
                </div>
            </nav>}
            </span>
    );

}