import React, { useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import { disdici, getCalendario, prenota } from '../DAO/calendarioDAO';
import { getPrenotazioni } from '../DAO/prenotazioniDAO';
import { setcalendariocliente } from '../STORE/reducers/gestoreCalendari';
import { setprenotazionicliente } from '../STORE/reducers/gestorePrenotazioniCliente';
import './prenotazione.css'
import { caricacorsifigli, getsolocorsidisponibili } from '../DAO/corsiDAO';

export default function RigaCalendarioGiornaliero(props) {
  const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
  const token = useSelector(state => state.gestoreUtente.token);

  const [dialogPrenoVisible, setDialogPrenoVisible] = useState(false);
  const [dialogDisdVisible, setDialogDisdVisible] = useState(false);
  const tipo = useSelector(state => state.gestoreTipiProdotto.tipo);

  const [corsi, setcorsi] = useState([])
  const [idcorso, setidcorso] = useState('')

  const dispatch = useDispatch();

  function confermaPreno(idschedulazione) {
    async function confermaPrenotazione(idschedulazione) {
      try {
        const msgPrenotazione = await prenota(
          anagrafica.email,
          anagrafica.idpalestra,
          idschedulazione,
          anagrafica.id,
          idcorso
        );
        alert(msgPrenotazione.message);
        caricaCalendario();
      } catch (error) {
        alert(error.response.data.message);
      }
    }
    confermaPrenotazione(idschedulazione);
  }

  function confermaDisd(idschedulazione, idprenotazione) {
    async function confermaDisdetta(idschedulazione, idprenotazione) {
      const msgPrenotazione = await disdici(
        anagrafica.email,
        anagrafica.idpalestra,
        idschedulazione,
        idprenotazione,
        anagrafica.id,
      );
      alert(msgPrenotazione);
      caricaCalendario();
    }
    confermaDisdetta(idschedulazione, idprenotazione);
  }

  const alertPreno = () => {
    return (
      <span>
      {dialogPrenoVisible&&
        <div className="alert alert-success">
         
              <p>
                {props.app.description} ({props.app.location})
              </p>
              <button onClick={() => setDialogPrenoVisible(false)}>
                Chiudi
              </button>
              <button
                onClick={() => {
                  confermaPreno(props.app.schedulazione.id);
                  setDialogPrenoVisible(false);
                }}>
                PRENOTA
              </button>
      </div>}
      </span>
    );
  };

  const alertDisdetta = () => {
    return (
      <div>
          {dialogDisdVisible&&<div >
            <div >
              <p>
                {props.app.description} ({props.app.location})
              </p>
              <button onClick={() => setDialogDisdVisible(false)}>
                Chiudi
              </button>
              <button
                onClick={() => {
                  confermaDisd(
                    props.app.schedulazione.id,
                    props.app.idPrenotazione,
                  );
                  setDialogDisdVisible(false);
                }}>
                Disdici
              </button>
            </div>
          </div>}
      </div>
    );
  };

  function apriLink(link){
    window.open(link,'mywin','width=500,height=500');
  }

  function caricaCalendario() {
    async function caricaCalend() {
      const calendariodb = await getCalendario(
        anagrafica.email,
        anagrafica.idpalestra,
        tipo.id,
        anagrafica.id,
        token
      );
      dispatch(setcalendariocliente(calendariodb));
      
      const prenotazionidb = await getPrenotazioni(
        anagrafica.email,
        null,
        anagrafica.idpalestra,
        anagrafica.id,
        null
      );
      dispatch(setprenotazionicliente(prenotazionidb));
    }
    caricaCalend();
  }

  function caricaCorsi(){
    async function caricac(){
      const result = await getsolocorsidisponibili(anagrafica.email, anagrafica.idpalestra, props.app.schedulazione.idtipocorso);
      //console.log(result,'corsi')
      setcorsi( result)
    }
    caricac()
  }

  function caricaCorsiFigli(){
      async function caricafigli(){
              const result=await caricacorsifigli(anagrafica.email, anagrafica.idpalestra, '', props.app.schedulazione.corso, token)
              setcorsi(result)
      }
      caricafigli()
  }

  useEffect(() => {
    if (!props.app.schedulazione.corso
    &&props.app.schedulazione.idtipocorso){
      caricaCorsi();
    }
    if (props.app.schedulazione.corso){
      caricaCorsiFigli()
    }
    //console.log(props.app)
  },[])


  return (
    <div className="time-slot">
      
              <h3 style={{backgroundColor: props.app.backgroundColor, color:'white'}}>
                {props.app.schedulazione && props.app.schedulazione.ora} 
                ({ props.app.schedulazione && props.app.schedulazione.durata} min.)
                </h3>
                <div className="product">
                  {props.app.schedulazione&& props.app.schedulazione.urlcorso && 
                     <a href="#" onClick={() => apriLink(props.app.schedulazione.urlcorso)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
</svg>
                      </a>
                  }
                    {props.app.schedulazione.immagine&&
                    <img className="product-image" src={props.app.schedulazione.immagine} alt="Product A" />
                    }
                    <p>{props.app.schedulazione && props.app.schedulazione.descrizione}{' '}
                
                   
                  {props.app.backgroundColor !== 'red' ? (
                    <p style={{ textAlign: 'center'}}>
                      {props.app.actual} / {props.app.max}
                    </p>
                  ) : null}

                  </p>
{
  corsi&&Array.isArray(corsi)&&corsi.length>0&&
  <select name="idcorso" value={props.app.idcorso} disabled={props.app.idcorso&&props.app.idcorso!==''} onChange={(e) => setidcorso(e.target.value)} >
    <option>--seleziona un prodotto--</option>
    {corsi.map(corso => (
      <option key={corso.id} value={corso.id}>{corso.nome}</option>
    ))}
  </select>
}
                  {alertPreno()}
                  {alertDisdetta()}
                {props.app.backgroundColor !== 'red' &&
                props.app.backgroundColor !== 'gray' &&
                !props.app.idPrenotazione ? (
                  <button onClick={() => setDialogPrenoVisible(true)}>
                    Prenota!
                  </button>
                  
                ) : null}
                {props.app.backgroundColor !== 'red' &&
                props.app.backgroundColor !== 'gray' &&
                props.app.idPrenotazione ? (
                  <button
                    onClick={() => {
                      setDialogDisdVisible(true);
                    }}>
                  {props.app.riserva?"Riserva n. "+props.app.riserva+" - ":null} Disdici 
                  </button>
                ) : null}
                {props.app.backgroundColor === 'red' ||
                props.app.backgroundColor === 'gray' ? (
                  <p style={{fontSize: 11, marginLeft:10}}>{props.app.description}</p>
                ) : null}
            </div>
      <hr />
    </div>
  );
}