import React, { useEffect, useState } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrash, faEuroSign} from '@fortawesome/free-solid-svg-icons'
import RicevuteCliente from "../ricevute/RicevuteCliente";
import { getPalClienteFromAbb } from "../DAO/abbonamentoDAO";

export default function AbbonamentoCliente(props) {
    const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const token = useSelector(state => state.gestoreUtente.token);

    const [abbonamento, setabbonamento] = useState(props.abbonamento);
    const [palcliente, setpalcliente] = useState(null)

    function eliminaabb(){
        if (window.confirm('Sei sicuro di voler eliminare abbonamento selezionato?')){
            const formData = new FormData();
            formData.append('email',email);
            formData.append('website', global.config.domain);
            formData.append('lic','000');
            formData.append('id',abbonamento.id);
            formData.append('palestra',palestra.id);

            axios.post(global.config.server+"/eliminaAbbonamentoRest",formData)
            .then(response=>{
                console.log("Abbonamenti: ")
                console.log(response.data)
                alert(response.data)
                props.setkeytorefresh(new Date().getTime())
                //window.location.reload()
            })
            .catch(err => {
                if (err.response){
                    alert("abbonamenti: "+err.response.data.message);
                }
                else{
                    alert("abbonamenti: "+err.response);
                }
            });
        }
    }

    function pagaOra() {
        localStorage.setItem("dapagare","abb_"+props.abbonamento.id)
        window.open((abbonamento.pagaoraurl&&abbonamento.pagaoraurl!=='undefined'?abbonamento.pagaoraurl:"https://annotatio.online/pagamento-utente-popu/")+'?&datap=abb_'+props.abbonamento.id,"_blank")
    }

    function abbonamentoScaduto(dataScadenzaAbbonamento){
        let today=new Date()
        return (new Date(dataScadenzaAbbonamento)<today)
        }
    
        //solo se palestra è Annotatio
    function caricapalestracliente(){
        async function getpalcliente(){
            const result = await getPalClienteFromAbb(abbonamento.id, email, token);
            setpalcliente(result)
        }
        getpalcliente()
    }

    useEffect(() => {
        if (palestra.id===80){
            caricapalestracliente()
        }
    },[palestra, email, props.abbonamento])

    return (
        <span>
            {abbonamento?
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                        
                        <div className="row" style={{backgroundColor:abbonamentoScaduto(abbonamento.validoA)?"#ffa7a7":"#ceffce"}}>
                            <div className="col" style={{borderColor:'black', borderStyle:'double'}}>
                            <h5 className="card-title">{abbonamento.listino?abbonamento.listino.tipoAbbonamento.descrizione+" "+abbonamento.listino.periodo.descrizione:" - "}
                            </h5>
                            
                            {palestra.id===80&&
                            <div>{palcliente?palcliente.palestra:"Strano, nessuna attivita commerciale associata a questo abbonamento"}</div>
                            }
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label>{abbonamentoScaduto(abbonamento.validoA)?"SCADUTO":"VALIDO"} <span style={{backgroundColor:'white', padding:5, display:"block"}} className="border border-primary">da {abbonamento.validoDa} a {abbonamento.validoA}</span>
                                                    </label>
                                                </div>
                                                <div className="col-md-6">
                                                <label>Data operazione <span style={{backgroundColor:'white', padding:5, display:"block"}} className="border border-primary">{abbonamento.dataOperazione}</span></label>
                                                
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                <label>Prezzo <span style={{backgroundColor:'white', padding:5, display:"block"}} className="border border-primary">{abbonamento.prezzo}</span></label>
                                                
                                                </div>
                                                <div className="form-group col-md-6">
                                                <label>Scadenza pagamento <span style={{backgroundColor:'white', padding:5, display:"block"}} className="border border-primary">{abbonamento.dataScadenzaPagamento}</span></label>
                                                </div>
                                            </div>
                                            {abbonamento.sconto&&abbonamento.sconto.id&&<div className="row">
                                                <div className="form-group col-md-6">
                                                <label>Sconto <span style={{backgroundColor:'white', padding:5, display:"block"}} className="border border-primary">{abbonamento.sconto.descrizione} ({abbonamento.sconto.valoreSconto}%)</span></label>
                                                
                                                </div>
                                                
                                            </div>}
                                       
                                   
                            </div>
                            <div className="col">
                                <h5 className="card-title">Pagamenti registrati ({abbonamento.totPagamenti}€)</h5>
                                {abbonamento.pagamenti&&Array.isArray(abbonamento.pagamenti)&&abbonamento.pagamenti.length>0?
                                    <span>
                                    {abbonamento.pagamenti.map(pagamento=>(
                                        <div key={pagamento.id} className="form-row">
                                            <div className="form-group col-md-2">
                                                <label>Data</label>
                                                <input type="text" className="form-control" value={pagamento.dataPagamento} />
                                                </div>
                                                <div className="form-group col-md-3">
                                                <label>Importo</label>
                                                <input type="text" className="form-control" value={pagamento.importo} />
                                                </div>
                                                <div className="form-group col-md-4">
                                                <label>Tipo</label>
                                                <input type="text" className="form-control" value={pagamento.tipo}/>
                                                </div>
                                        </div>
                                    ))}
                                    
                                    </span>
                                :<span>
                                Nessun pagamento effettuato<br />
                                {abbonamento.saldo>0?
                                <a className="btn btn-success" role="button" onClick={pagaOra} href="#">
                                    <FontAwesomeIcon icon={faEuroSign} />&nbsp;
                                    Paga ora</a>
                                    :null} &nbsp;
                                <button onClick={eliminaabb} className="btn btn-danger">
                                <FontAwesomeIcon icon={faTrash} />&nbsp;
                                    Cancella Abbonamento</button>
                                </span>}
                                
                            </div>
                            
                            </div>
                            <div className="row">
                                <div className="col">
                                <RicevuteCliente email={email} idabbonamento={props.abbonamento.id} ricevute={props.abbonamento.ricevute}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            :null}
        </span>
);
}