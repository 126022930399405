import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {navigate} from 'hookrouter'
import { setfatturericercate } from "../STORE/reducers/gestoreFattura";

import { cercafatture, esportafatture } from "../DAO/fattureDAO";
import '../index.css'

var timeout=3000
var fatturedaesportare=[];
export default function RicercaFatture (props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraAnagrafica);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);

    const fatturericercate = useSelector(state => state.gestoreFatture.fatturericercate);
    const dispatch = useDispatch();

    const[nome, setnome] = useState('')
    const[cognome, setcognome] = useState('')
    const[codicefiscale, setcodicefiscale] = useState('')
    const[numero, setnumero] = useState('')
    const[datada, setdatada] = useState('')
    const[dataa, setdataa] = useState('')
    const[nomecognomecodfisc, setnomecognomecodfisc] = useState('')
    const [totfatture, settotfatture] = useState(0)
    const [fatture, setfatture] = useState([])

    function ricercafatture(codfisc){
        console.log('ricercafatture '+codfisc)
        async function cercafatt(){
            try{
                setfatture([])
                fatturedaesportare=[]
                console.log('prefatture: '+codicefiscale)
            const result = await cercafatture(codfisc?'':nomecognomecodfisc, codfisc?'':nome, codfisc?'':cognome, codfisc?codfisc:codicefiscale, codfisc?'':numero, codfisc?'':datada, codfisc?'':dataa,palestra.id, email);
            console.log(result)
            setfatture(result)
            let totfatt=0;
            result.map(fatt => totfatt+=fatt.importoricevuta)
            settotfatture(totfatt)
            dispatch(setfatturericercate(result));
            }
            catch(error){
                console.log(error)
            }
        }
        cercafatt();
    }

    function esportafattura(checked, value){
        console.log(checked+' '+value)
        let fatturetemp=fatturedaesportare;
        if (checked){
            if (!fatturetemp.includes(value)){
                fatturetemp.push(value)
            }
        }
        else{
            fatturetemp=fatturetemp.filter(fatt => fatt!==value)
        }
        
        fatturedaesportare=fatturetemp;
    }

    function esportaemail(emailforexport){
        async function exportfatt(){
            const result= await esportafatture(fatturedaesportare, email, palestra.id, "IT"+palestra.piva+"_"+new Date().getFullYear()+""+(("0" + (new Date().getMonth() + 1)).slice(-2))+""+("0" + new Date().getDate()).slice(-2)+'.zip', emailforexport);
        }
        if (fatturedaesportare&&Array.isArray(fatturedaesportare)&&fatturedaesportare.length>0){
            exportfatt();
        }
        else{
            alert('Selezionare almeno una fattura')
        }
    }

      useEffect(() => {
        ricercafatture(anagrafica.codicefiscale)
      },[anagrafica])

    return (
    		<div className="container-fluid">
                <div className="row">
                    <div className="col-md-3"><h4>Fatture</h4></div>
                </div>

                <div className="table-responsive">
                    <table className="table table-hover " >
                        <thead>
                            <tr>
                            <th>Numero/anno</th>
                            <th>Data</th>
                            <th></th>
                            <th>Nome</th>
                            <th>Cognome</th>
                            <th>Cod fisc</th>
                            <th>Data nascita</th>
                            <th>Importo</th>
                            <th>Data export</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(fatture&&fatture.length>0?fatture:fatturericercate).map(fattura => (
                            <tr key={fattura.idfatt}>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.numeroricevuta}/{fattura.annoricevuta }
                           </td>
                           <td style={{cursor:"pointer", whiteSpace: "nowrap"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.dataricevuta}
                            </td>
                            <td>
                            </td>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.nome}
                                </td>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.cognome}
                            </td>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.piva}{fattura.codicefiscale}
                                </td>
                            <td style={{cursor:"pointer", whiteSpace: "nowrap"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                            {fattura.datanascita}
                            </td>
                            <td style={{cursor:"pointer", textAlign:'right'}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.importoricevuta}
                            </td>
                            <td style={{cursor:"pointer"}} onClick={() => fattura.idfatt?navigate("/annotatio-dett-fatt/"+fattura.idfatt):alert("Nessuna fattura per l'anagrafica "+fattura.nome+" "+fattura.cognome)}>
                                {fattura.fedataexport}
                            </td>
                            <td>
                                {fattura.idfatt?
                                <input type="checkbox" name="export" id={"export"+fattura.idfatt} value={fattura.idfatt} onClick={(e) => esportafattura(e.target.checked, fattura.idfatt)} />
                                :""
                                }
                            </td>
                            </tr>
))}
<tr><td colSpan={7} style={{textAlign:'right'}}>Tot.</td><td style={{textAlign:'right'}}>{totfatture}</td><td colSpan={2}></td></tr>
                        </tbody>
                    </table>
                    
                </div>
            </div>
    );
}